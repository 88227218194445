import React from 'react';

import * as Constants from '~/src/constants';

const GatsbyGamePage: React.FC = () => {
  React.useEffect(() => {
    if ([
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel',
    ].includes(navigator.platform)) {
      window.location.replace(Constants.DOWNLOAD_LINK_APP_STORE);
    } else {
      window.location.replace(Constants.DOWNLOAD_LINK_PLAY_STORE);
    }
  }, []);
  return null;
};

export default GatsbyGamePage;
